import React, { useCallback } from "react";
import { Link, Route, Switch, useRouteMatch, useHistory, useParams } from "react-router-dom";
import { useSettings, useReduxSelector } from "onekijs";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";


export default () => {

  const params = useParams();
  const settings = useSettings();
  const history = useHistory();
  const token = useReduxSelector('auth.token.access_token');

  const requestInterceptor = useCallback(
    req => {
      req.headers.Authorization = "Bearer " + token;
      req.headers['x-api-key'] = settings.api.apiKey;
      return req;
    },
    [token],
  )

  const onSelect = useCallback(
    (e) => {
      const service = e.target.value;
      history.push(`/services/${service}`);
    },
    [history],
  )  

  return (
    <>
      <div className="swagger-ui" style={{textAlign: "right", paddingRight:"20px"}}>
        <span style={{marginRight: "5px"}}><b>Service:</b></span> 
        <Select services={['billing', 'party', 'policy', 'pricing']} onSelect={onSelect}/>
      </div>

      <SwaggerUI
        url={`${settings.api.baseUrl}/doc/v1/swaggers/${params.service}`}
        docExpansion="list"
        requestInterceptor={requestInterceptor}
      />
    </>
  );
};

const Select = ({services, onSelect}) => {
  return (
    <select onChange={onSelect} style={{width: "200px", height:"40px"}}>
      {services.map(service => (
        <option key={service} value={service}>{service}</option>
      ))}
    </select>
  )
}
