import { useLogoutService, useSetting } from 'onekijs';
import React from 'react';
import { Redirect } from 'react-router-dom';

export default React.memo(() => {
  const state = useLogoutService('cognito');

  if (state.loading) {
    return <div>Loading...</div>
  } else if(state.errorMessage) {
    return <div style={{padding: '10px', background: 'red', color: 'white'}}>{state.errorMessage}</div>
  }
  return <Redirect to='/'/>
});