import { useSetting, useSecurePost } from "onekijs";
import React, { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import SubmitButton from "../../components/submitButton";


export default () => {
  // baseUrl is defined in settings.js
  const baseUrl = useSetting("api.baseUrl");
  const [cypherTexts, setCypherTexts] = useState(null);

  const onSuccess = useCallback(
    (data, context) => {
      setCypherTexts(data);
    },
    [setCypherTexts],
  )

  const [post, submitLoading] = useSecurePost(`${baseUrl}/doc/v1/encryptor`, { onSuccess });

  const { register, handleSubmit, errors } = useForm();

  return (
    <div style={{ padding: "10px 50px" }}>
      <div className="page-header">
        <h1 id="containers">Jasypt Encryptor</h1>
        <p className="lead">
          Use the encryptor tool to encrypt the password stored in a Spring boot
          configuration file
        </p>
      </div>
      <div style={{ marginTop: "15px" }}>
        <form>
          <div>
            <b>Plain text: </b>
            <input
              name="plaintext"
              ref={register({ required: true })}
              style={{ width: "100%" }}
            />
            {errors.plaintext && <span> This field is required</span>}
          </div>
          <div style={{"marginTop": "10px"}}>
            <SubmitButton
              label="Encrypt"
              loading={submitLoading}
              onClick={handleSubmit(post)}
            />
          </div>
        </form>
      </div>
      {cypherTexts && !submitLoading && (
        <ul>
          <li><b>Local: </b><span>{cypherTexts.local}</span></li>
          <li><b>Dev: </b><span>{cypherTexts.dev}</span></li>
          <li><b>Tst: </b><span>{cypherTexts.tst}</span></li>
          <li><b>Acc: </b><span>{cypherTexts.acc}</span></li>
          <li><b>Prd: </b><span>{cypherTexts.prd}</span></li>
        </ul>
      )}
    </div>
  );
};
