import { useReduxSelector, useSettings } from "onekijs";
import React from "react";
import { useLocation } from "react-router-dom";
import "./index.css";
import Documentation from "../../components/documentation";

export default () => {
  const settings = useSettings();
  const token = useReduxSelector("auth.token.access_token");
  const location = useLocation();

  return <Documentation
    baseUrl={settings.api.baseUrl}
    service="doc"
    hash={location.hash}
    token={token}
  />;
};
