import { secure, useSecurityContext } from "onekijs";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Redirect, Route, Switch, Link } from "react-router-dom";
import '../../assets/bootstrap.min.css';
import '../../assets/custom.css';
import Service from "../../routes/services";
import Encryptor from "../../routes/encryptor/Encryptor";
import Doc from "../../routes/doc";


const Main = React.memo(() => {
  const email = useSecurityContext('email');
  
  const [show, setShow] = useState(false);
  console.log("render main", show);
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark" style={{marginBottom: "20px"}}>
        <NavLink to="/services" className="navbar-brand" style={{color: "#FFA500"}}>Flora API</NavLink>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarColor02">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <NavLink to="/doc" className="nav-link">Documentation</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/services" className="nav-link">Services</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/encryptor" className="nav-link">Encryptor</NavLink>
            </li>            
          </ul>
          <div className="form-inline my-2 my-lg-0">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" id="download" onClick={() => setShow(!show)}>{email} <span className="caret"></span></a>
                <div className={show ? 'dropdown-menu show':'dropdown-menu'} aria-labelledby="download">
                  <Link className="dropdown-item" to="/logout">Logout</Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>      
      <Switch>
        <Route path="/doc">
          <Doc />
        </Route>
        <Route path="/encryptor">
          <Encryptor />
        </Route>
        <Route path="/services">
          <Service />
        </Route>
        <Redirect from='/' to='/services'/>
      </Switch>
    </div>
  );
});

export default secure(Main);
