import { useReduxSelector, useSettings } from "onekijs";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Documentation from "../../../components/documentation";
import "./index.css";

export default () => {
  const params = useParams();
  const settings = useSettings();
  const token = useReduxSelector("auth.token.access_token");
  const location = useLocation();

  return <Documentation 
    baseUrl={settings.api.baseUrl}
    service={params.service}
    hash={location.hash}
    token={token}
  />;
};
