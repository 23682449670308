import React from "react";
import "./index.css";

export default ({service, token, hash, baseUrl}) => {
  let url = `${baseUrl}/doc/v1/asciidoc/${token}/${service}/docs/index.html`;
  if (hash) {
    url += hash;
  }

  return <iframe title="documentation" src={url} />;
};
