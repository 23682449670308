import { App } from "onekijs";
import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router-dom";
import "./i18n";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import settings from "./settings";
import Main from "./components/main/Main";
import Login from "./routes/login";
import Logout from "./routes/logout";

ReactDOM.render(
  <App settings={settings}>
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>

      <Route>
        <Main /> {/* <Main /> is the default layout of the website */}
      </Route>
    </Switch>
  </App>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
